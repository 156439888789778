import { faArrowsAltH, faBed } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
import React, {useState} from "react";
import '../Card/Card.css'
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import CardSeller from "../Card/CardSeller/CardSeller";

const CardAangekocht = (props) => {
    const styles = props.styles
    const data = props.data
    const sellerData = props.seller
    const priceInt = parseInt(data.aangekochteWoningen.koopprijs)
    const priceCurr = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(priceInt).replace(',00', ',-');
   
    const length = props.length

    const koopConditie = data.aangekochteWoningen.koopconditie
    const price = `${priceCurr} ${koopConditie}`
   
    let img;

    const key = props.delay
    const animDelay = key !== undefined ? key * 0.15 : 0
    const woonoppervlak = <span>{data.aangekochteWoningen.woonoppervlakte}m²</span>

    let seller = null
    
    seller = sellerData.nodes.filter((sell) => {
        if(data.categories.nodes.includes(sell.verkopendeMakelaars.vestiging)){
            return true;
        } else{ return false }
    })
    const sellerName = seller.length > 0 ? seller[0].title : ''
    const sellerVestiging = seller.length > 0 ? `` : `Groenhout ${data.categories.nodes[0].name}`
    const phoneNumber = seller.length > 0 ? seller[0].verkopendeMakelaars.telefoonnummer : ''
    const image = seller.length > 0 ? seller[0].verkopendeMakelaars.foto.mediaItemUrl : ''
    const phoneDisplay = phoneNumber !== '' ?
        phoneNumber.startsWith("06") ?  
            phoneNumber.substr(0,2) + ' - ' + phoneNumber.substr(2) 
        :
            phoneNumber.substr(0,4) + ' - ' + phoneNumber.substr(4) 
        :
        '' 

    if(data.aangekochteWoningen.hoofdfoto?.mediaItemUrl !== null ){
        img = data.aangekochteWoningen.hoofdfoto?.mediaItemUrl;
    }
    const [cardState, setCardState] = useState(null)
    return(
        <>
        <div className="h-full">
        <Link to={data.uri}>
            <motion.div layoutId={key} initial={{translateY: 75, opacity: 0}} animate={{translateY: 0, opacity: 1}} transition={{duration: 0.2, delay: animDelay }} onClick={() => setCardState(key)} className={` card ${length > 3 ? ``: `max-h-8/12`} h-full p-0 transition-all duration-300 ease-in ${styles} col-span-1 row-span-1 relative `} >
                    <div className="object-cover w-full h-3/6 m-0 image_container ">
                        <div className="flex row ">
                            <div className="max-h-full w-full">
                                {data.aangekochteWoningen.hoofdfoto?.mediaItemUrl !== null ?
                                <img src={img} alt={`Woning aan de ${data.title}`} className={`h-48 max-h-2/6 object-cover w-full mb-0 z-10`}></img>
                                : 
                                null
                                }
                            </div>
                        </div>
                        <div className="flex flex-row p-5 bg-light justify-between w-full">
                            <div className="flex flex-col h-full w-full">
                                <h3 className="text-blue text-lg m-0">{data.title}</h3>
                                <span className="text-blue opacity-40 text-sm "><span className="uppercase">{data.hqAdresPostcode}</span> {data.hqAdresPlaats}</span>
                                <span className="text-blue text-sm my-3">{woonoppervlak} {data.hqKamers !== "" ? <span className="mx-1">&bull;</span> : null} <span>{data.aangekochteWoningen.kamers} {data.aangekochteWoningen.kamers !== "" ? 'kamers' : ''}</span></span>
                                <h3 className="text-blue text-sm lg:text-xl mx-0 mb-3">{price}</h3>
                                
                                {/* <CardSeller data={data} seller={seller} huur={data.hqHuurconditie !== ''}></CardSeller> */}
                            </div>
                        </div>
                    </div>
            </motion.div>
        </Link>
        </div>
        </>
    )
}

export default CardAangekocht;