import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import CardGridAangekocht from "../../components/AangekochtComponents/CardGridAangekocht";
import FilterSidebarAangekocht from "../../components/AangekochtComponents/FilterSidebarAangekocht";
import FilterSummaryAangekocht from "../../components/AangekochtComponents/FilterSummaryAangekocht";
import Container from "../../components/Container/Container";
import ContentImageLeft from "../../components/ContentImageLeft/ContentImageLeft";
import Seo from "../../components/Seo/Seo";
import SmallHero from "../../components/SmallHero/SmallHero";
import SmallHeroNew from "../../components/SmallHeroNew/SmallHeroNew";
import { useAangekochtQuery } from "../../hooks/useAagekochtQuery";
import G from "../../images/groenhout_g.png"

const AangekochtPage = () => {
    const data = useAangekochtQuery()
    const img = data.allWpAangekochteWoning.nodes.lenght > 0 ? data.allWpAangekochteWoning.nodes[0].featuredImage.node.mediaItemUrl : ''
    
    const [maxValue, setMaxValue] = useState('')
    const [minValue, setMinValue] = useState('')
    const [minOpp, setMinOpp] = useState('')
    const [maxOpp, setMaxOpp] = useState('')
    const [type, setType] = useState('')
    const [plaats, setPlaats] = useState([])
    const [slaapkamers, setSlaapkamers] = useState('')
    const [count, setCount] = useState()

    const onChangeHandler = (event) => {
        console.log(event.currentTarget.value)
        if(event.target.name === 'type'){
            setType(event.target.value)
        } else if(event.target.name === 'min'){
            setMinValue(event.target.value)
        } else if(event.target.name === 'max'){
            setMaxValue(event.target.value)
        } else if (event.target.name === 'oppMax'){
            setMaxOpp(event.target.value)
        } else if (event.target.name === 'oppMin'){
            setMinOpp(event.target.value)
        } else if (event.target.name === 'plaats'){
            const plaatsArr = plaats;
            console.log(plaatsArr)
            plaatsArr.includes(event.target.value) ? setPlaats(plaatsArr.filter((e)=>{
                return e !== event.target.value
            })) : setPlaats([...plaats, event.target.value])
        }  else if(event.currentTarget.value === 'slaapkamers'){
            setSlaapkamers(event.target.value)
        }  else if(event.target.name === 'remove'){
            setMaxValue('')
            setMinValue('')
            setType('')
            setMaxOpp('')
            setMinOpp('')
            setPlaats([])
            setSlaapkamers('')
        }   else if(event.currentTarget.name === 'remove_single'){
            if(event.currentTarget.value === 'minBedrag'){
                setMinValue('')
            } else if(event.currentTarget.value === 'maxBedrag'){
                setMaxValue('')
            } else if(event.currentTarget.value === 'type'){
                setType('')
            } else if(event.currentTarget.value === 'minOpp'){
                setMinOpp('')
            } else if(event.currentTarget.value === 'maxOpp'){
                setMaxOpp('')
            } else if(event.currentTarget.value === 'Slaapkamers'){
                setSlaapkamers('')
            } else if(event.currentTarget.value === 'Plaatsen'){
                setPlaats([])
            }
        }
    }

    const postCount = (data) => {
        setCount(data)
    }

    const seo = useStaticQuery(
        graphql`
        query AangekochtPage {
            wpPage(slug: {eq: "aangekocht"}) {
                seo{
                    metaDesc
                    title
                  }
                  content
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
        } `
      )

      

    return(
       <div>
           <Seo title={seo.wpPage.seo.title} description={seo.wpPage.seo.metaDesc}></Seo>
            <SmallHeroNew title={'Door ons aangekocht'} overlay={true} overlayImage={G} image={img !== undefined ? img : ''}></SmallHeroNew>
            <Container styles="w-full max-w-none my-16 md:px-16 px-4">
                <FilterSummaryAangekocht count={count} changeHandler={onChangeHandler} type={type} minVal={minValue} maxVal={maxValue} minOpp={minOpp} maxOpp={maxOpp} plaats={plaats} slaapkamers={slaapkamers}></FilterSummaryAangekocht>
                <Container styles={'w-full max-w-none flex flex-row justify-between lg:mt-20 mt-4 aanbod__container'}>
                    <Container styles={'flex lg:flex-row flex-col justify-between'}>
                        {/* <FilterSidebarAangekocht selectedPlaats={plaats} data={data} type={type} oppMax={maxOpp} oppMin={minOpp} slaapkamers={slaapkamers} min={minValue} max={maxValue} changeHandler={onChangeHandler}></FilterSidebarAangekocht> */}
                        <CardGridAangekocht count={postCount} data={data} type={type} minVal={minValue} maxVal={maxValue} oppMax={maxOpp} oppMin={minOpp} plaats={plaats} ></CardGridAangekocht>
                    </Container>
                </Container>
                {/* <ContentImageLeft bg={true}></ContentImageLeft> */}
            </Container>     
            <div className="text-content">
                <Container>
                    <div dangerouslySetInnerHTML={{ __html: seo.wpPage.content }}></div>
                </Container>
            </div>
       </div>
    )
}

export default AangekochtPage;